<template>
  <v-dialog v-model="dialog" persistent max-width="30%">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar class="toolbar-color">
          <span class="headline ml-2">
            {{ labelBtn != "Salvar" ? "Editar Meta" : "Cadastrar Meta" }}
          </span>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row align="center">
            <v-col lg="12" md="12" sm="12" cols="12">
              <v-text-field
                v-model="formTipoMeta.tipo_meta"
                label="Tipo Meta"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col lg="12" md="12" sm="12" cols="12">
              <v-text-field
                v-model="formTipoMeta.detalhes"
                label="Detalhes"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="4" md="4">
              <v-autocomplete
                label="Status"
                :items="[
                  { text: 'Sim', value: 'S' },
                  { text: 'Não', value: 'N' }
                ]"
                :rules="[rules.required]"
                v-model="formTipoMeta.ativo"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6" sm="4" md="4">
              <v-autocomplete
                label="Tipo Metas"
                :items="[
                  { text: 'Sim', value: 'S' },
                  { text: 'Não', value: 'N' }
                ]"
                :rules="[rules.required]"
                v-model="formTipoMeta.tipo_metas"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6" sm="4" md="4">
              <v-autocomplete
                label="Tipo Pagamento"
                :items="[
                  { text: 'Sim', value: 'S' },
                  { text: 'Não', value: 'N' }
                ]"
                :rules="[rules.required]"
                v-model="formTipoMeta.tipo_pagamento"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close()">Fechar</v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="labelBtn != 'Salvar' ? put() : send()"
          >{{ labelBtn }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from "@/mixins/inputRules";
import campanha from "@/services/http/campanhaService";

export default {
  name: "ModalTipoMeta",
  mixins: [rules],
  props: {
    tipoMeta: {
      type: Object
    },
    dialog: {
      type: Boolean,
      default: false
    },
    labelBtn: {
      type: String
    }
  },

  data() {
    return {
      formTipoMeta: {}
    };
  },
  watch: {
    tipoMeta(value) {
      this.formTipoMeta = value;
    }
  },

  methods: {
    close() {
      this.$emit("close");
      this.$refs.form.reset();
    },
    async send() {
      const validateStatus = this.$refs.form.validate();
      if (validateStatus) {
        await campanha()
          .tipoMeta()
          .store(this.formTipoMeta, {
            notification: true,
            message: "Meta Cadastrada com sucesso!"
          });
      }
      this.close();
    },
    async put() {
      const validateStatus = this.$refs.form.validate();
      if (validateStatus) {
        await campanha()
          .tipoMeta(this.formTipoMeta.id_tipo_meta)
          .update(this.formTipoMeta, {
            notification: true,
            message: "Meta editada com sucesso!"
          });
      }
      this.close();
    }
  }
};
</script>
