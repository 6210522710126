<template>
  <v-menu :close-on-content-click="false" max-width="320" offset-x>
    <template v-slot:activator="{ on }">
      <FilterBottom v-on="on" />
    </template>
    <v-card class="pa-3">
      <v-container>
        <p>
          Status
        </p>
        <v-switch
          v-model="filters.ativo"
          true-value="S"
          false-value="N"
          :label="`${filters.ativo == 'S' ? 'Ativo' : 'Inativo'}`"
          @change="handleFilter()"
        >
        </v-switch>
        <!-- <p>
          Tipo Pagamento
        </p>
          <v-switch
          v-model="filters.tipo_pagamento"
          true-value="S"
          false-value="N"
          :label="`${filters.tipo_pagamento == 'S' ? 'Ativo' : 'Inativo'}`"
          @change="handleFilter()"
        >
        </v-switch>
         <p>
          Tipo Meta
        </p>
          <v-switch
          v-model="filters.tipo_metas"
          true-value="S"
          false-value="N"
          :label="`${filters.tipo_metas == 'S' ? 'Ativo' : 'Inativo'}`"
          @change="handleFilter()"
        >
        </v-switch>-->
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
import FilterBottom from "@/components/shared/bottons/FilterBottom";
export default {
  components: {
    FilterBottom
  },
  data() {
    return {
      filters: { ativo: "S" }
    };
  },
  methods: {
    handleFilter() {
      this.$emit("selectedFilters", this.filters);
    }
  }
};
</script>
