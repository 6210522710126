var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseTable',{staticClass:"table-shadow",attrs:{"headers":_vm.headers,"loading":_vm.loading,"paginate":true,"items":_vm.items,"sort-by":"tipo_meta"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Lista de Tipos de Metas")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('RegisterBottom',{directives:[{name:"can-access",rawName:"v-can-access",value:(601),expression:"601"}],on:{"click":function($event){(_vm.dialog = true), (_vm.labelBtn = 'Salvar')}}}),_c('Filtro',{on:{"selectedFilters":function($event){_vm.fetchTipoMeta((_vm.filters = $event))}}})],1)]},proxy:true},{key:"item.ativo",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.statusAtivo[item.ativo].color,"dark":""}},[_vm._v(" "+_vm._s(_vm.statusAtivo[item.ativo].text)+" ")])]}},{key:"item.tipo_pagamento",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.statusAtivo[item.tipo_pagamento].color,"dark":""}},[_vm._v(" "+_vm._s(_vm.statusAtivo[item.tipo_pagamento].text)+" ")])]}},{key:"item.tipo_metas",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.statusAtivo[item.tipo_metas].color,"dark":""}},[_vm._v(" "+_vm._s(_vm.statusAtivo[item.tipo_metas].text)+" ")])]}},{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [_c('IconBottom',{directives:[{name:"can-access",rawName:"v-can-access",value:(602),expression:"602"}],attrs:{"name":'edit'},on:{"click":function($event){return _vm.editItem(item)}}}),_c('IconBottom',{directives:[{name:"can-access",rawName:"v-can-access",value:(603),expression:"603"}],attrs:{"name":'delete'},on:{"click":function($event){return _vm.deleteItem(item)}}})]}}],null,true)}),_c('ModalTipoMeta',{attrs:{"tipoMeta":_vm.meta,"dialog":_vm.dialog,"labelBtn":_vm.labelBtn},on:{"close":function($event){return _vm.refresh()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }